export interface PropertiesChatBot {
  title: string;
  profilePicture: string;
  name: string;
}

export enum ComponentType {
  SWITCH = "switch",
  TASK = "task",
}

export enum TaskType {
  CHOICE = "choice",
  INPUT_NUMBER = "inputNumber",
  INPUT_TEXT = "inputText",
  TEXT = "text",
  RESPONSE = "response",
}

export interface BaseSequence {
  id: string;
  componentType: ComponentType;
  name: string;
}

export interface SwitchSequence extends BaseSequence {
  type: TaskType.CHOICE;
  properties: ChoiceProperties;
  branches: { [key: string]: SequenceItem[] };
}

export interface TaskSequence extends BaseSequence {
  type: TaskType;
  properties: TaskProperties;
}

export interface ChoiceProperties {
  propertyName: string;
  branches: { [key: string]: { title: string } };
}

export type TaskProperties =
  | InputNumberProperties
  | InputTextProperties
  | TextProperties;

export interface InputNumberProperties {
  propertyName: string;
  maxNumberofDecimal: number;
  min: number;
}

export interface InputTextProperties {
  propertyName: string;
  type: string;
}

export interface TextProperties {
  text: string;
}

export type SequenceItem = SwitchSequence | TaskSequence;

export interface ChatBot {
  properties: PropertiesChatBot;
  sequence: SequenceItem[];
}

export enum CategoryEnum {
  HOUSE = "Hébergement",
  FOOD = "Alimentaire",
  CHILD = "Enfance/Jeunesse",
  STUDY = "Insertion formation",
  MEDIATION = "Médiation",
  CLOTHES = "Vestiaire",
  LEGAL = "Conseils juridiques",
  ADMINISTRATION = "Aide administrative",
  SOCIAL_EMERGENCY = "Urgence sociale",
  HEALTH = "Santé",
  HANDICAP = "Handicap",
  INCEST = "Lutte contre l'inceste",
  WORK = "Accompagnement à l'Emploi",
  MATERIAL = "Matériel",
  CANCER = "Lutte contre le  cancer",
  ECO_MOBILITY = "Eco mobilité",
  ADDICTION = "Addictions",
  HYGIENE = "Kit d'hygiène",
}
