import { alpha, Components } from "@mui/material";
import { Colors } from "../constants/colors.constant";

declare module "@mui/material/ButtonGroup" {
  interface ButtonGroupPropsColorOverrides {
    neutral: true;
  }
}

const button: Components = {
  MuiButton: {
    styleOverrides: {
      root: (props) => ({
        color: Colors.white,
        borderRadius: 16,
        padding: "11px 20px",
        textTransform: "none",
        "&:hover": {
          color: Colors.primary,
        },
      }),
      contained: (props) => ({
        backgroundColor: Colors.primary,
        boxShadow: "none",
        color: Colors.white,
        height: "min-content",
        "&.Mui-disabled": {
          backgroundColor: Colors.primary,
          color: alpha(Colors.white, 0.5),
        },
        "&:hover": {
          backgroundColor: Colors.primary,
          color: Colors.white,
        },
      }),
    },
  },
};

export default button;
