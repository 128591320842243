// @ts-ignore
import PoppinsMedium from './Poppins/Poppins-Medium.ttf';
// @ts-ignore
import PoppinsBold from './Poppins/Poppins-Bold.ttf';
// @ts-ignore
import PoppinsLight from './Poppins/Poppins-Light.ttf';
// @ts-ignore
import PoppinsRegular from './Poppins/Poppins-Regular.ttf';

const fonts = `
  @font-face {
    font-family: 'Poppins';
    font-weight: 300;
    src: local("PoppinsLight"),
    url(${PoppinsLight}) format("truetype");
  }
  @font-face {
    font-family: 'Poppins';
    font-weight: 400;
    src: local("PoppinsRegular"),
    url(${PoppinsRegular}) format("truetype");
  }
  @font-face {
    font-family: 'Poppins';
    font-weight: 500;
    src: local("PoppinsMedium"),
    url(${PoppinsMedium}) format("truetype");
  }
  @font-face {
    font-family: 'Poppins';
    font-weight: 700;
    src: local("PoppinsBold"),
    url(${PoppinsBold}) format("truetype");
  }
`;

export default fonts;
