import { Stack, styled, Typography } from "@mui/material";
import React from "react";
import { Colors } from "../constants/colors.constant";
import checkBlue from "../assets/images/check_blue.svg";
import AppDownloadLinksComponent from "../components/AppDownloadLinks.component";

const Container = styled(Stack)`
  background: ${Colors.white};
  border-radius: 1rem;
  padding: 1.875rem 1.5rem;
  -webkit-box-shadow: 0 8px 24px -4px rgba(69, 55, 44, 0.04);
  box-shadow: 0 8px 24px -4px rgba(69, 55, 44, 0.04);
`;

const ReportConfirmationScreen = () => {
  return (
    <Stack spacing={2.5} margin={2}>
      <Container
        justifyContent="center"
        alignItems="center"
        className="text-center"
      >
        <img src={checkBlue} alt="blue check" />
        <Typography
          fontSize={18}
          fontWeight={800}
          marginBottom="1.25rem"
          marginTop=".75rem"
        >
          Confirmation de votre signalement
        </Typography>
        <Typography>
          Nous vous remercions pour votre signalement. Nous faisons tout notre
          possible pour aider au mieux les personnes. Votre signalement sera
          validé très vite afin que le réseau des aidants puisse intervenir.
        </Typography>
      </Container>
      <AppDownloadLinksComponent />
    </Stack>
  );
};

export default ReportConfirmationScreen;
