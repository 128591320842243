import React from "react";
import WorkIcon from "./../assets/icons/work.webp";
import HygieneIcon from "./../assets/icons/hygiene.webp";
import IncestIcon from "./../assets/icons/incest.webp";
import LegalIcon from "./../assets/icons/legal.webp";
import MaterialIcon from "./../assets/icons/material.webp";
import MediationIcon from "./../assets/icons/mediation.webp";
import StudyIcon from "./../assets/icons/study.webp";
import HandicapIcon from "./../assets/icons/handicap.webp";
import HealthIcon from "./../assets/icons/health.webp";
import HouseIcon from "./../assets/icons/house.webp";
import EcoMobilityIcon from "./../assets/icons/ecomobility.webp";
import EmergencyIcon from "./../assets/icons/emergency.webp";
import FoodIcon from "./../assets/icons/food.webp";
import ClothingIcon from "./../assets/icons/clothing.webp";
import ChildIcon from "./../assets/icons/child.webp";
import CancerIcon from "./../assets/icons/cancer.webp";
import AddictionIcon from "./../assets/icons/addiction.webp";
import VolunteerIcon from "./../assets/icons/volunteer.webp";

import { CategoryEnum } from "../services/chat.model";

export const getCategoryIcon = (
  categoryKey: keyof typeof CategoryEnum,
): string => {
  switch (categoryKey) {
    case "ADMINISTRATION":
      return VolunteerIcon;
    case "WORK":
      return WorkIcon;
    case "FOOD":
      return FoodIcon;
    case "HYGIENE":
      return HygieneIcon;
    case "INCEST":
      return IncestIcon;
    case "LEGAL":
      return LegalIcon;
    case "MATERIAL":
      return MaterialIcon;
    case "MEDIATION":
      return MediationIcon;
    case "STUDY":
      return StudyIcon;
    case "HANDICAP":
      return HandicapIcon;
    case "HEALTH":
      return HealthIcon;
    case "HOUSE":
      return HouseIcon;
    case "ECO_MOBILITY":
      return EcoMobilityIcon;
    case "SOCIAL_EMERGENCY":
      return EmergencyIcon;
    case "CLOTHES":
      return ClothingIcon;
    case "CHILD":
      return ChildIcon;
    case "CANCER":
      return CancerIcon;
    case "ADDICTION":
      return AddictionIcon;
    default:
      return "";
  }
};
