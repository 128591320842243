import { Components } from "@mui/material";

const typography: Components = {
  MuiTypography: {
    styleOverrides: {
      root: () => ({
        fontSize: "14px",
        fontWeight: "400px",
      }),
    },
  },
};

export default typography;
