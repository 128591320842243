import { Alert, Button, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GoogleMapsAutocompleteComponent from '../components/GoogleMapsAutocomplete.component';
import { Colors } from '../constants/colors.constant';

const MAX_DISTANCE_IN_KM = 50;

const LocationScreen = (props: any) => {
  const navigate = useNavigate();
  const [location, setLocation] = useState<{
    location: { lat: number; long: number };
    address: string;
  }>({
    location: {
      lat: 0,
      long: 0,
    },
    address: '',
  });
  const [distanceInKm, setDistanceInKm] = useState<number>(0);

  const calculateDistance = (location: { lat: number; long: number }) => {
    const marseilleCoordinates = new google.maps.LatLng(43.2961743, 5.3699525);

    const userCoordinates = new google.maps.LatLng(
      location.lat,
      location.long
    );

    const distanceInMeters =
      google.maps.geometry.spherical.computeDistanceBetween(
        marseilleCoordinates,
        userCoordinates
      );

    const distanceInKm = distanceInMeters / 1000;

    setDistanceInKm(distanceInKm);
  }

  const handleLocationChange = (
    location: { lat: number; long: number },
    address: string
  ) => {
    setLocation({ location, address });
    calculateDistance(location);
  };


  const handleContinue = () => {
    if (
      location.location.lat !== 0 &&
      location.location.long !== 0 &&
      distanceInKm < MAX_DISTANCE_IN_KM
    ) {
      navigate('/chatbot', { state: { location } });
    }
  };

  return (
    <Stack
      direction='column'
      justifyContent='space-between'
      spacing={1}
      height='100vh'
      bgcolor={Colors.white}
      p={2}
    >
      <Stack direction='column' spacing={2}>
        <Typography fontSize={22} fontWeight={700}>
          Nouveau signalement
        </Typography>
        <Typography fontWeight={400}>
          Quelle est la localisation de la situation que vous signalez ?
        </Typography>
      </Stack>

      <Stack position={'relative'}>
        <GoogleMapsAutocompleteComponent
          onLocationChange={handleLocationChange}
        />
        {distanceInKm > MAX_DISTANCE_IN_KM && (
          <Alert
            severity='error'
            style={{ position: 'absolute', top: 60, width: '100%' }}
          >
            Fonctionnalité à venir pour votre zone géographique.
          </Alert>
        )}
      </Stack>
      <Button
        color='primary'
        variant='contained'
        onClick={handleContinue}
        disabled={
          !(location.location.lat !== 0 && location.location.long !== 0 && distanceInKm < MAX_DISTANCE_IN_KM)
        }
      >
        Continuer
      </Button>
    </Stack>
  );
};

export default LocationScreen;
