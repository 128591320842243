import React from "react";
import { TextField } from "@mui/material";

interface TextFieldComponentProps {
  required?: boolean;
  placeholder?: string;
  handleChange?: (value: string) => void;
  type?: string;
  value?: string | number;
}

const TextFieldComponent = (props: TextFieldComponentProps) => {
  const { handleChange, placeholder, type, value } = props;

  return (
    <TextField
      placeholder={placeholder}
      type={type}
      value={value}
      onChange={(evt) => handleChange?.(evt.target.value)}
    />
  );
};

export default TextFieldComponent;
