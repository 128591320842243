import axios, { AxiosRequestConfig } from "axios";

const APIAxios = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
});

export const APIRoutes = {
  POSTReportingChatbot: (): AxiosRequestConfig => ({
    method: "POST",
    url: `/reporting-chatbot`,
  }),
};

export default APIAxios;
