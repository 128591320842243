import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";

interface NumberTextFieldComponentProps {
  placeholder?: string;
  handleChange?: (value?: number) => void;
  value?: number;
}

const NumberTextFieldComponent = (props: NumberTextFieldComponentProps) => {
  const { handleChange, placeholder, value } = props;

  const [inputValue, setInputValue] = useState(value || "");

  useEffect(() => {
    if (value === 0) {
      setInputValue("0");
      return;
    }
    if (inputValue !== value?.toString())
      setInputValue(value !== undefined ? value.toString() : "");
  }, [value]);

  return (
    <TextField
      placeholder={placeholder}
      value={inputValue}
      onChange={(evt) => {
        let value = evt.target.value.replace(",", ".").slice(0, 15);
        evt.target.value = value;
        setInputValue(value);
        if (!value) return;
        const floatValue = parseFloat(value);
        if (!!value && !isNaN(floatValue)) {
          handleChange?.(floatValue);
        }
      }}
    />
  );
};

export default NumberTextFieldComponent;
