import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useEffect, useState } from "react";

interface GoogleMapsAutocompleteProps {
  onLocationChange: (
    location: { lat: number; long: number },
    address: string,
  ) => void;
}

const GoogleMapsAutocompleteComponent = (
  props: GoogleMapsAutocompleteProps,
) => {
  const { onLocationChange } = props;

  const [options, setOptions] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);
  const [selectedOption, setSelectedOption] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchPlacePredictions = (query: string) => {
    const autocompleteService =
      new window.google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions({ input: query }, (predictions) => {
      setOptions(predictions || []);
    });
  };

  const handleInputChange = (event: any, value: string) => {
    setSelectedOption({ description: value });
    if (value.length > 2) {
      fetchPlacePredictions(value);
    }
  };

  const handleOptionSelected = async (event: any, value: any) => {
    setSelectedOption(value);

    if (value) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: value.description }, (results, status) => {
        if (
          status === "OK" &&
          results[0] &&
          results[0].geometry &&
          results[0].geometry.location
        ) {
          onLocationChange(
            {
              lat: results[0].geometry.location.lat(),
              long: results[0].geometry.location.lng(),
            },
            results[0].formatted_address,
          );
        }
      });
    }
  };

  const getReadableLocation = async (latitude: number, longitude: number) => {
    setIsLoading(true);
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`,
    );
    const data = await response.json();
    setIsLoading(false);
    return data.results && data.results[0]
      ? data.results[0].formatted_address
      : null;
  };

  useEffect(() => {
    setIsLoading(true);
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const readableLocation = await getReadableLocation(
            position.coords.latitude,
            position.coords.longitude,
          );
          if (readableLocation && typeof readableLocation === "string") {
            setSelectedOption({ description: readableLocation });
            onLocationChange(
              {
                lat: position.coords.latitude,
                long: position.coords.longitude,
              },
              readableLocation,
            );
          }
          setIsLoading(false);
        },
        (error) => {
          console.error("Geolocation error:", error);
          setIsLoading(false);
        },
      );
    }
  }, []);

  return (
    <Autocomplete
      value={selectedOption}
      options={options}
      getOptionLabel={(option) => option.description || ""}
      noOptionsText="Aucun résultat"
      onInputChange={handleInputChange}
      onChange={handleOptionSelected}
      disabled={isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          fullWidth
          disabled={isLoading}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default GoogleMapsAutocompleteComponent;
