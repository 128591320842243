import React, { useState } from "react";
import ChatBubbleComponent from "./ChatBubble.component";
import { Grid } from "@mui/material";
import {
  ChoiceProperties,
  ComponentType,
  TaskType,
} from "../services/chat.model";
import { Sizes } from "../constants/sizes.constant";

interface ChoiceBubbleProps {
  choices: ChoiceProperties["branches"];
  onChoiceSelected: (choiceKey: string) => void;
}

const ChoiceBubbleComponent = (props: ChoiceBubbleProps) => {
  const { choices, onChoiceSelected } = props;

  const [selectedChoice, setSelectedChoice] = useState<string | null>(null);

  const handleChoice = (choice: string) => {
    if (selectedChoice) return;
    setSelectedChoice(choice);
    onChoiceSelected(choice);
  };

  return (
    <Grid
      container
      spacing={Sizes.spacingChatsBot}
      wrap="wrap"
      justifyContent="flex-end"
      pt={0}
    >
      {Object.entries(choices).map(([key, { title }]) => (
        <Grid
          item
          xs={Object.entries(choices).length > 4 ? undefined : 12}
          key={key}
        >
          <ChatBubbleComponent
            item={{
              id: key,
              componentType: ComponentType.SWITCH,
              name: title,
              type: TaskType.CHOICE,
              properties: {
                propertyName: key,
                branches: choices,
              },
              branches: {},
            }}
            handleClick={() => handleChoice(key)}
            isUserResponse={true}
            selectedChoice={key === selectedChoice}
            clickable={!selectedChoice}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ChoiceBubbleComponent;
