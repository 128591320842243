import { alpha } from "@mui/material";

export const Colors: any = {
  primary: "#2B5AAC",
  secondary: `${alpha("#1067FF", 0.1)}`,
  background: "#F4F6F8",
  error: "#ff0000",
  white: "#fff",
  black: "#1E1E1E",
  lightGrey: "#F5F5F5",
};
