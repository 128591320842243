import {createTheme} from '@mui/material';
import palette from './palette.theme';
import fonts from '../assets/fonts';
import typography from './typography.theme';
import button from './button.theme';

const theme = createTheme({
    palette,
    typography: {
        fontFamily: "Poppins, sans-serif"
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `${fonts}`,
        },
        ...button,
        ...typography,
    }
})

export default theme;
