import React, { useEffect, useRef, useState } from "react";
import ChatBubbleComponent from "../components/ChatBubble.component";
import {
  ChoiceProperties,
  ComponentType,
  PropertiesChatBot,
  SequenceItem,
  SwitchSequence,
  TaskType,
} from "../services/chat.model";
import ChoiceBubbleComponent from "../components/ChoiceBubble.component";
import TextFieldComponent from "../components/TextField.component";
import NumberTextFieldComponent from "../components/NumberTextField.component";
import { Button, Skeleton, Stack } from "@mui/material";
import HeaderChatBotComponent from "../components/HeaderChatBot.component";
import { Colors } from "../constants/colors.constant";
import { useLocation, useNavigate } from "react-router-dom";
import { Pages } from "../utils/routes.utils";
import APIAxios, { APIRoutes } from "../api/axios.api";

interface ChatBotProps {
  properties: PropertiesChatBot;
  sequences: SequenceItem[];
}

const TYPING_DELAY = 1000;

const ChatBotScreen = (props: ChatBotProps) => {
  const { properties, sequences } = props;

  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);

  const [currentSequences, setCurrentSequences] =
    useState<SequenceItem[]>(sequences);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [userInputs, setUserInputs] = useState<Record<string, any>>({
    publicUserId: 0,
  });
  const [messageHistory, setMessageHistory] = useState<SequenceItem[]>([]);
  const [inputValue, setInputValue] = useState<string | number | undefined>(
    undefined,
  );
  const [displayTypingIndicator, setDisplayTypingIndicator] = useState(false);

  const [isChatFinished, setIsChatFinished] = useState(false);

  const navigate = useNavigate();

  const locationState = useLocation().state;

  useEffect(() => {
    if (locationState && locationState.location) {
      setUserInputs({
        ...userInputs,
        location: locationState.location.location,
        address: locationState.location.address,
      });
    }
  }, [locationState]);

  useEffect(() => {
    if (isChatFinished) {
      const config = APIRoutes.POSTReportingChatbot();

      APIAxios.request({ ...config, data: userInputs })
        .then((response) => {
          setTimeout(() => navigate("/" + Pages.CONFIRMATION), 3000);
        })
        .catch((error) => {
          console.error("Erreur", error);
        });
    }
  }, [isChatFinished]);

  const advanceToNextSequence = () => {
    if (currentIndex < currentSequences.length) {
      const sequence = currentSequences[currentIndex];
      setMessageHistory((prevHistory) => [...prevHistory, sequence]);

      if (
        sequence.componentType === ComponentType.TASK &&
        sequence.type === TaskType.TEXT
      ) {
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }
    } else {
      setIsChatFinished(true);
    }
  };

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    if (currentIndex < currentSequences.length) {
      setDisplayTypingIndicator(true);
      setTimeout(() => scrollToBottom(), 100);
    }
    setTimeout(() => {
      setDisplayTypingIndicator(false);
      advanceToNextSequence();
    }, TYPING_DELAY);
  }, [currentIndex, isFirstRender]);

  const scrollToBottom = () => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  };

  const handleUserResponse = (response: any) => {
    const currentSequence = currentSequences[currentIndex];
    if (!currentSequence) return;

    let propertyName: string | null = null;
    if ("propertyName" in currentSequence.properties) {
      propertyName = currentSequence.properties.propertyName;
    }

    const updatedInputs = { ...userInputs };

    if (propertyName) {
      if (
        [
          "anonymousReporterMail",
          "isForSomeoneElse",
          "title",
          "description",
        ].includes(propertyName)
      ) {
        updatedInputs[propertyName] = response;
      } else if (propertyName === "category") {
        updatedInputs[propertyName] = {
          ...updatedInputs[propertyName],
          category: response,
        };
      } else {
        if (!updatedInputs["category"]) {
          updatedInputs["category"] = {};
        }
        updatedInputs["category"].content = {
          ...updatedInputs["category"].content,
          [propertyName]: response,
        };
      }
    }

    setUserInputs(updatedInputs);

    if (currentSequence.componentType === ComponentType.SWITCH) {
      const nextBranch = (currentSequence as SwitchSequence).branches[response];
      if (nextBranch && nextBranch.length) {
        setCurrentSequences(nextBranch);
        setCurrentIndex(0);
      } else {
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }
    } else {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const confirmInputResponse = () => {
    handleUserResponse(inputValue);

    const userResponse = {
      id: `user_${Date.now()}`,
      componentType: ComponentType.TASK,
      name: inputValue as any,
      type: TaskType.RESPONSE,
      properties: { text: inputValue as any },
      branches: {},
    };

    setMessageHistory((prevHistory) => [...prevHistory, userResponse]);
    setInputValue("");
  };

  useEffect(() => {
    scrollToBottom();
  }, [messageHistory, userInputs]);

  const TypingIndicator = () => (
    <Stack direction="row" alignItems="center" spacing={0.5} m={3}>
      <Skeleton variant="circular" width={10} height={10} />
      <Skeleton variant="circular" width={10} height={10} />
      <Skeleton variant="circular" width={10} height={10} />
    </Stack>
  );

  const renderMessageHistory = () => {
    return messageHistory.map((sequence, index) => {
      switch (sequence.componentType) {
        case ComponentType.SWITCH:
          return (
            <React.Fragment key={index}>
              <ChatBubbleComponent item={sequence} />
              <ChoiceBubbleComponent
                choices={(sequence.properties as ChoiceProperties).branches}
                onChoiceSelected={handleUserResponse}
              />
            </React.Fragment>
          );
        case ComponentType.TASK:
          switch (sequence.type) {
            case TaskType.CHOICE:
              return (
                <ChoiceBubbleComponent
                  key={index}
                  choices={(sequence.properties as ChoiceProperties).branches}
                  onChoiceSelected={handleUserResponse}
                />
              );
            case TaskType.INPUT_NUMBER:
              return (
                <ChatBubbleComponent
                  index={index}
                  key={index}
                  item={sequence}
                />
              );
            case TaskType.INPUT_TEXT:
              return (
                <ChatBubbleComponent
                  index={index}
                  key={index}
                  item={sequence}
                />
              );
            case TaskType.TEXT:
              return (
                <ChatBubbleComponent
                  index={index}
                  key={index}
                  item={sequence}
                />
              );
            case TaskType.RESPONSE:
              return (
                <ChatBubbleComponent
                  index={index}
                  key={index}
                  item={sequence}
                  isUserResponse
                  selectedChoice
                />
              );
            default:
              return null;
          }
        default:
          return null;
      }
    });
  };

  const renderInputField = () => {
    const currentSequence = messageHistory[messageHistory.length - 1];
    if (
      currentSequence &&
      currentSequence.componentType === ComponentType.TASK
    ) {
      switch (currentSequence.type) {
        case TaskType.INPUT_NUMBER:
          return (
            <>
              <NumberTextFieldComponent
                value={inputValue as number | undefined}
                handleChange={(value) => setInputValue(value)}
              />
              <Button
                color="primary"
                variant="contained"
                onClick={() => confirmInputResponse()}
                disabled={!inputValue || isNaN(Number(inputValue))}
              >
                Confirmer
              </Button>
            </>
          );
        case TaskType.INPUT_TEXT:
          return (
            <>
              <TextFieldComponent
                value={inputValue}
                handleChange={(value) => setInputValue(value)}
              />
              <Button
                color="primary"
                variant="contained"
                onClick={() => confirmInputResponse()}
                disabled={!inputValue}
              >
                Confirmer
              </Button>
            </>
          );
        default:
          return null;
      }
    }
    return null;
  };

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      style={{ height: "100vh", backgroundColor: Colors.white }}
    >
      <HeaderChatBotComponent properties={properties} />
      <Stack
        ref={chatContainerRef}
        direction="column"
        style={{ height: "100%", overflowY: "scroll", overflowX: "hidden" }}
        p={2}
      >
        {renderMessageHistory()}
        {displayTypingIndicator && <TypingIndicator />}
      </Stack>
      {!displayTypingIndicator &&
        currentSequences[currentIndex] &&
        (currentSequences[currentIndex].type === TaskType.INPUT_NUMBER ||
          currentSequences[currentIndex].type === TaskType.INPUT_TEXT) && (
          <Stack direction="column" spacing={2} pb={4} px={2}>
            {renderInputField()}
          </Stack>
        )}
    </Stack>
  );
};

export default ChatBotScreen;
