import React from "react";
import { Avatar, Stack, styled, Typography } from "@mui/material";
import { PropertiesChatBot } from "../services/chat.model";
import { Colors } from "../constants/colors.constant";
import logoChat from "../assets/images/chat_logo.png";

interface HeaderChatBotProps {
  properties: PropertiesChatBot;
}

const HeaderStyled = styled(Stack)({
  backgroundColor: Colors.primary,
  padding: "1.75rem 1.25rem",
});

const HeaderChatBotComponent = (props: HeaderChatBotProps) => {
  const { properties } = props;

  return (
    <HeaderStyled direction="row" alignItems="center" spacing={2}>
      <Avatar alt="chatbot avatar" src={logoChat} />

      <Stack direction="column">
        <Typography fontSize={18} fontWeight={600} color={Colors.white}>
          {properties.name}
        </Typography>
        <Typography color={Colors.white}>{properties.title}</Typography>
      </Stack>
    </HeaderStyled>
  );
};

export default HeaderChatBotComponent;
