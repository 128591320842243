import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeScreen from "./screens/Home.screen";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./themes";
import ChatBotScreen from "./screens/ChatBot.screen";
import ChatBotJson from "./services/ChatBot.json";
import { SequenceItem } from "./services/chat.model";
import ReportConfirmationScreen from "./screens/ReportConfirmation.screen";
import { Pages } from "./utils/routes.utils";
import LocationScreen from "./screens/Location.screen";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Box
          sx={{
            width: "100%",
            maxWidth: { xs: "100%", md: "600px" },
            margin: "0 auto",
          }}
        >
          <CssBaseline />
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path={`/${Pages.LOCATION}`} element={<LocationScreen />} />
            <Route
              path={`/${Pages.CHATBOT}`}
              element={
                <ChatBotScreen
                  properties={ChatBotJson.properties}
                  sequences={ChatBotJson.sequence as SequenceItem[]}
                />
              }
            />
            <Route
              path={`/${Pages.CONFIRMATION}`}
              element={<ReportConfirmationScreen />}
            />
          </Routes>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;
