import React from "react";
import { Avatar, Paper, Stack, Typography } from "@mui/material";
import { Colors } from "../constants/colors.constant";
import {
  CategoryEnum,
  ComponentType,
  SequenceItem,
  TaskType,
  TextProperties,
} from "../services/chat.model";
import ChatBotJson from "./../services/ChatBot.json";
import { Sizes } from "../constants/sizes.constant";
import { getCategoryIcon } from "../utils/icon.utils";
import logoChat from "../assets/images/chat_logo.png";

interface ChatBubbleProps {
  index?: number;
  item: SequenceItem;
  isUserResponse?: boolean;
  selectedChoice?: boolean;
  clickable?: boolean;
  handleClick?: () => void;
}

const ChatBubbleComponent = (props: ChatBubbleProps) => {
  const {
    index,
    item,
    handleClick,
    isUserResponse = false,
    selectedChoice = false,
    clickable = false,
  } = props;

  let showAvatar = false;
  let additionalStyle = {};

  const isBotMessage: boolean =
    item.componentType === ComponentType.TASK ||
    (item.componentType === ComponentType.SWITCH &&
      item.type === TaskType.CHOICE &&
      !isUserResponse);

  if (isBotMessage && item.type !== TaskType.RESPONSE) {
    showAvatar = true;
    if (index === undefined) {
      additionalStyle = { marginBottom: Sizes.spacingChatsBot };
    }
  }

  let bgColor: string = Colors.lightGrey;
  let textColor: string = Colors.black;

  if (isUserResponse) {
    if (selectedChoice) {
      bgColor = Colors.primary;
      textColor = Colors.white;
    } else {
      bgColor = Colors.secondary;
      textColor = Colors.primary;
    }
  }

  const iconSrc = Object.keys(CategoryEnum).includes(item.id)
    ? getCategoryIcon(item.id as keyof typeof CategoryEnum)
    : null;

  console.log(item);

  const onBubbleClick = () => {
    if (clickable && handleClick) {
      handleClick();
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent={isUserResponse ? "end" : "start"}
      spacing={1}
    >
      {showAvatar && <Avatar src={logoChat} />}
      <Paper
        elevation={0}
        onClick={onBubbleClick}
        style={{
          ...additionalStyle,
          backgroundColor: bgColor,
          color: textColor,
          cursor: clickable ? "pointer" : "default",
          padding: "14px",
          borderRadius: "16px",
          alignSelf: isUserResponse ? "flex-end" : "flex-start",
          marginTop: isBotMessage ? Sizes.spacingChatsBot : "",
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          {iconSrc && (
            <img src={iconSrc} alt={item.id} width={18} height={18} />
          )}
          <Typography fontSize="15px" fontWeight={500}>
            {item.type === TaskType.TEXT
              ? (item.properties as TextProperties).text
              : item.name}
          </Typography>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default ChatBubbleComponent;
