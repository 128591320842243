import {
  alpha,
  Avatar,
  Box,
  Button,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo_horizontal.png';
import ReportIcon from '../assets/images/report.svg';
import AppDownloadLinksComponent from '../components/AppDownloadLinks.component';
import { Colors } from '../constants/colors.constant';
import { Pages } from '../utils/routes.utils';

const ReportContainer = styled(Stack)`
  background: ${Colors.primary};
  color: ${Colors.white};
  border-radius: 1rem;
  padding: 0.75rem 1.25rem;
  -webkit-box-shadow: 0 8px 12px -4px rgba(184, 184, 208, 0.2);
  box-shadow: 0 8px 12px -4px rgba(184, 184, 208, 0.2);
  cursor: pointer;
`;

const HomeScreen = () => {
  const navigate = useNavigate();

  return (
    <Stack>
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        className='p-5 bg-white'
      >
        <img src={logo} alt='logo' style={{ padding: '0.5rem' }} />
      </Box>
      <Stack direction='column' spacing={3.75} className='py-7 px-5'>
        <Stack direction='column'>
          <Typography fontSize={22} fontWeight={700}>
            Bienvenue !
          </Typography>
          <Typography fontWeight={400}>
            Vous ou une autre personne a besoin d’aide ? Nous vous accompagnons
            dans votre signalement.
          </Typography>
        </Stack>

        <ReportContainer
          direction='row'
          spacing='1.25rem'
          alignItems='center'
          onClick={() => navigate(Pages.LOCATION)}
        >
          <Avatar
            src={ReportIcon}
            alt='report icon'
            style={{
              backgroundColor: alpha(Colors.white, 0.2),
              height: '3.125rem',
              width: '3.125rem',
              padding: '0.75rem 0',
            }}
          />
          <Stack>
            <Typography fontSize={20} fontWeight={700}>
              Signaler
            </Typography>
            <Typography fontSize={12} fontWeight={400}>
              Effectuer un nouveau signalement pour informer le centre d’aide le
              plus proche
            </Typography>
          </Stack>
        </ReportContainer>

        <AppDownloadLinksComponent />
        <Stack>
          <a
            href='https://www.helloasso.com/associations/plus-avenir-solidarite/formulaires/1'
            target='_blank'
          >
            <Button
              color='primary'
              variant='contained'
              style={{ width: '100%' }}
            >
              <Typography fontSize={20} fontWeight={500}>
                Faire un don
              </Typography>
            </Button>
          </a>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default HomeScreen;
