import { Stack, styled, Typography } from "@mui/material";
import React from "react";
import { Colors } from "../constants/colors.constant";
import appStore from "../assets/images/appstore.png";
import googlePlay from "../assets/images/googleplay.png";

const Container = styled(Stack)`
  background: ${Colors.white};
  border-radius: 1rem;
  padding: 1.875rem 1.5rem;
  -webkit-box-shadow: 0 8px 24px -4px rgba(69, 55, 44, 0.04);
  box-shadow: 0 8px 24px -4px rgba(69, 55, 44, 0.04);
`;
const AppDownloadLinksComponent = () => {
  return (
    <Container spacing={3}>
      <Stack spacing={1.5}>
        <Typography fontSize={18} fontWeight={800} className="color-blue">
          Téléchargement de l’application
        </Typography>
        <Typography>
          Pour suivre votre signalement, veuillez télécharger l’application +
          Avenir Connect et vous créer un compte.
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-around">
        <a href="https://apps.apple.com/app/id6449833497">
          <img
            src={appStore}
            alt="App App Store"
            style={{ height: "2.5rem" }}
          />
        </a>
        <a href="https://play.google.com/store/apps/details?id=fr.plusavenir.app&pcampaignid=web_share">
          <img
            src={googlePlay}
            alt="App Google Play"
            style={{ height: "2.5rem" }}
          />
        </a>
      </Stack>
    </Container>
  );
};

export default AppDownloadLinksComponent;
